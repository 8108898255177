<template>
  <div class="microcart-container">
    <div
      class="microcart cl-accent"
      data-testid="microcart"
    >
      <div v-if="instantPaymentComplete">
        <div :class="cartContainerClass">
          <payment-complete
            :is-successful="instantPaymentSuccess"
          />
        </div>
      </div>
      <div
        class="microcart-inner"
        v-else-if="cartIsLoaded"
      >
        <div :class="cartContainerClass">
          <div v-if="productsInCart.length">
            <div class="row">
              <div class="small-6 column cart__headline">
                <h4 class="h--body-font">
                  {{ yourCartText }}
                </h4>
              </div>
              <div class="small-6 column cart__close-button-container">
                <button
                  class="cart__close-btn"
                  @click="closeMicrocartExtend"
                  data-testid="closeMicrocart"
                >
                  <svg
                    class="cart__close-button"
                    viewBox="0 0 28 28"
                  >
                    <use xlink:href="#close_button" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="cart__products-container">
              <transition-group
                enter-active-class="animated fadeIn slow"
                @enter="animationOnEnter"
              >
                <product
                  v-for="product in productsInCart"
                  :product="product"
                  :key="product.id"
                  @loading="setLoadingState"
                />
              </transition-group>
              <transition
                enter-active-class="animated fadeInUp slow"
                leave-active-class="animated fadeOut slow"
              >
                <div v-if="!hideTotal">
                  <div
                    class="cart__total-container h6 h--body-font h--body-size--sm"
                    v-if="showDeliveryPriceInBasket || showExpressCheckout"
                  >
                    <div class="row">
                      <div class="small-6 column cart__total-text">
                        Subtotal
                      </div>
                      <div
                        class="small-6 column cart__total-text cart__total-value"
                      >
                        <div v-if="loadingTotal">
                          <svg
                            width="30"
                            height="12"
                          >
                            <use xlink:href="#loading_animation" />
                          </svg>
                        </div>
                        <div v-else>
                          {{ getLocalisedPriceSubtotal }}
                        </div>
                      </div>
                    </div>
                    <discount-voucher
                      v-if="showExpressCheckout"
                      :add-voucher-pressed="addVoucherPressed"
                      :voucher-error="voucherError"
                      :voucher-error-msg="voucherErrorMsg"
                      :voucher-applied="voucherApplied"
                      :input-as-placeholder="inputAsPlaceholder"
                      :loading-total="loadingTotal"
                      @addDiscountVoucher="addDiscountVoucher"
                      @clearVoucher="clearVoucher"
                      @setVoucher="setVoucher"
                      @resetVoucherError="resetVoucherError"
                      @disableLoadingState="disableLoadingState"
                      @inputAsPlaceholderCheck="inputAsPlaceholderCheck"
                    />
                    <div
                      class="row js-cart-delivery-price"
                      v-if="showDeliveryPriceInBasket"
                    >
                      <div class="small-10 column cart__total-text">
                        Delivery
                        <span
                          v-if="belowFreeDeliveryThreshold"
                          class="text--weight-normal cart__delivery-msg"
                        >(free standard delivery over £{{ freeDeliveryThreshold }})</span>
                      </div>
                      <div
                        class="small-2 column cart__total-text cart__total-value"
                      >
                        <div v-if="loadingTotal">
                          <svg
                            width="30"
                            height="12"
                          >
                            <use xlink:href="#loading_animation" />
                          </svg>
                        </div>
                        <div v-else>
                          {{ getDeliveryPriceStr }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :class="[(showDeliveryPriceInBasket || showExpressCheckout) ? 'h6' : 'h5', 'row', 'cart__total-container', 'h--body-font']">
                    <div class="small-6 column cart__total-text">
                      {{ totalText }}
                    </div>
                    <div
                      class="small-6 column cart__total-text cart__total-value"
                      :data-total="getTotal"
                    >
                      <div v-if="loadingTotal">
                        <svg
                          width="30"
                          height="12"
                        >
                          <use xlink:href="#loading_animation" />
                        </svg>
                      </div>
                      <div v-else>
                        {{ getLocalisedPrice }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <recommendation
                @loading="setLoadingState"
                @hideTotal="setHideTotal"
                :products-in-cart="productsInCart"
                :show-gift-wrapping="showGiftWrapping"
                :show-small-plants="true"
              />
            </div>
          </div>
          <div v-else>
            <empty-cart />
          </div>
        </div>
        <div
          :class="checkoutBtnClass"
          v-if="!loadingTotal && productsInCart.length"
        >
          <checkout-btn
            :key="checkoutBtnKey"
            :get-latest-checkout-btn-uuid="getLatestCheckoutBtnUuid"
            :below-minimum-threshold="belowMinimumThreshold"
            :total="getTotal"
            :totalToPay="getTotalToPayValue"
            :loading-total="loadingTotal"
            :all-in-stock="isAllInStock"
            :stock-check-complete="stockCheckComplete"
            @rerender="forceRerenderCheckoutBtn"
            @checkoutBtnUuidGenerated="recordLatestCheckoutBtnUuid"
            @eligibleEvent="instantPayEligibileEvent"
            @doInstantPaymentComplete="doInstantPaymentComplete"
          />
        </div>
      </div>
      <div v-else>
        <div :class="cartContainerClass">
          <empty-cart :is-loading="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueOfflineMixin } from 'vue-offline';
import { mapGetters } from 'vuex';
import Microcart
  from '../../../../../core/compatibility/components/blocks/Microcart/Microcart';
import onEscapePress from '../../../../../core/mixins/onEscapePress';
import Product from './Product.vue';
import EmptyCart from './EmptyCart.vue';
import PaymentComplete from './PaymentComplete.vue';
import Recommendation from './Recommendation.vue';
import DiscountVoucher from './DiscountVoucher.vue';
import { getCurrencyStr } from '../../../../../utils/currencyFormatting';
import { freeDeliveryThreshold, getDeliveryPrice } from '../../../../../utils/deliveryHelpers';
import { postSavedEvents, postTrackEvent, saveEvent } from '../../../../../utils/analytics';
import { getPrefix } from '../../../../../utils/prefix';
import { isMobile } from '../../../../../utils/deviceHelpers';
import * as Sentry from '@sentry/vue';

export default {
  components: {
    Product,
    EmptyCart,
    CheckoutBtn: (resolve) => require(['./CheckoutBtn.vue'], resolve),
    Recommendation,
    PaymentComplete,
    DiscountVoucher
  },
  mixins: [
    Microcart,
    VueOfflineMixin,
    onEscapePress
  ],
  data () {
    return {
      addVoucherPressed: false,
      voucherCode: '',
      voucherError: false,
      voucherErrorMsg: '',
      inputAsPlaceholder: false,
      componentLoaded: false,
      PATCH_I18N: {},
      microcart: null,
      loadingTotal: false,
      btnHover: false,
      longContainer: false,
      minimumValueError: false,
      belowMinimumThreshold: false,
      belowFreeDeliveryThreshold: false,
      hideTotal: false,
      showGiftWrapping: false,
      freeDeliveryThreshold: freeDeliveryThreshold,
      checkoutBtnKey: 0,
      latestCheckoutBtnUuid: 'no-uuid-recorded',
      instantPayEligibileEventSent: false,
      instantPaymentComplete: false,
      instantPaymentSuccess: false,
      stockCheckComplete: false,
    };
  },
  created() {
    this.showGiftWrapping = window.showGiftWrapping;
  },
  props: {
    isCheckoutMode: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      cartMinimumOrderThreshold: 'cart/getMinimumOrderThreshold',
      cartIsLoaded: 'cart/getCartIsLoaded',
      voucherApplied: 'cart/getVoucher'
    }),
    checkoutBtnClass() {
      return {
        row: true,
        'cart__checkout-continue-container': true,
        animated: true,
        fadeInUp: true,
        'delay-1s': true,
        'cart__checkout-continue-container-bottom': this.longContainer,
      };
    },
    cartContainerClass() {
      return {
        cart__container: true,
        'cart__container-long': this.longContainer,
        'cart__container--complete': this.instantPaymentComplete,
        animated: true,
        fadeInRight: true,
      };
    },
    woohooText() {
      return PATCH_I18N.cart.woohoo;
    },
    yourCartText() {
      return PATCH_I18N.cart.your_bag;
    },
    secureCheckoutText() {
      return PATCH_I18N.cart.continute_checkout;
    },
    totalText() {
      return PATCH_I18N.cart.total;
    },
    getDeliveryPriceStr() {
      return this.getDeliveryPriceValue > 0 ? getCurrencyStr(this.getDeliveryPriceValue) : 'FREE';
    },
    getDeliveryPriceValue() {
      return getDeliveryPrice(this.getTotal, this.showDeliveryPriceInBasket);
    },
    showDeliveryPriceInBasket() {
      return this.productsInCart.some(product => product.is_shipping_required) && !this.belowMinimumThreshold;
    },
    getTotal() {
      const total = this.totals.find(t => t.code === 'grand_total');
      if (total) {
        return total.value;
      }
      return null;
    },
    isAllInStock() {
      return this.productsInCart.every(product => product.is_in_stock);
    },
    getTotalToPayValue(){
      return this.calculateTotalToPay();
    },
    getLocalisedPrice() {
      const total = this.calculateTotalToPay();
      if (Number.isNaN(total)) {
        this.loadingTotal = true;
        return 'loading';
      } else {
        this.loadingTotal = false;
      }
      return total > 0 ? getCurrencyStr(total) : 'FREE';
    },
    getLocalisedPriceSubtotal() {
      const subtotal = this.getTotal;
      return subtotal && !Number.isNaN(subtotal) ? getCurrencyStr(subtotal) : null;
    },
    showExpressCheckout() {
      return window.showExpressCheckout;
    },
  },
  beforeMount () {
    this.$bus.$on('cart-after-updatetotals', this.disableLoadingState);
  },
  watch: {
    productsInCart: {
      immediate: true,
      deep: true,
      handler() {
        this.changeCheckoutBtnClass();
      }
    },
    totals: {
      immediate: true,
      deep: true,
      handler() {
        if (this.getTotal < this.cartMinimumOrderThreshold && this.productsInCart.length) {
          this.belowMinimumThreshold = true;
          postTrackEvent('Order taken below minimum value', { value: true });
        } else {
          this.belowMinimumThreshold = false;
        }
        if (this.getTotal < this.freeDeliveryThreshold && this.productsInCart.length) {
          this.belowFreeDeliveryThreshold = true;
        } else {
          this.belowFreeDeliveryThreshold = false;
        }
        this.loadingTotal = false;
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true;
    });
    this.PATCH_I18N = window.PATCH_I18N;

    this.changeCheckoutBtnClass(true);

    const cartFadeOut = document.querySelector('.cart-fadeout');
    cartFadeOut.addEventListener('click', () => {
      this.closeMicrocartExtend();
      postTrackEvent('Cart closed by clicking away', { value: true });
    });

    const token = this.$store.getters['cart/getCartToken'];
    saveEvent('Cart page', {
      checkout_id: token,
      order_token: token,
      total: this.getTotal,
      number_of_products: this.productsInCart.length,
      products: this.productsInCart
    });

    // get the latest stock status
    if (this.productsInCart && this.productsInCart.length > 0) {
      const productsInCartSkus = this.productsInCart.map(product => product.sku);
      this.$store.dispatch('product/list', {
        query: productsInCartSkus
      }, { root: true }).then((resp) => {
        resp.forEach(apiVariant => {
          this.productsInCart.find(cartProduct => cartProduct.sku === apiVariant.sku).is_in_stock = apiVariant.is_in_stock;
        });
        this.stockCheckComplete = true;
      });
    };
  },
  beforeDestroy() {
    const cartFadeOut = document.querySelector('.cart-fadeout');
    cartFadeOut.removeEventListener('click', this.closeMicrocartExtend);
    postSavedEvents();
  },
  methods: {
    animationOnEnter(el) {
      /* this is triggered when the product component enters the cart - eg. when
      the recommendation is added, and makes sure that the total element is hidden
      (with transition) so we can run the animations smoothly */
      el.addEventListener('animationend', () => {
        this.hideTotal = false;
      });
      setTimeout(() => {
        // fallback as animationend event is not reliably fired by all browsers
        this.hideTotal = false;
      }, 1000);
    },
    setHideTotal() {
      this.hideTotal = true;
    },
    disableLoadingState() {
      this.loadingTotal = false;
    },
    changeCheckoutBtnClass (unconditionally) {
      if (!this.componentLoaded && unconditionally !== true) return;
      const microcartContainer = this.$el;
      const checkoutBtnContainer = this.$el.querySelector('.cart__checkout-continue-container');
      const cartContainer = this.$el.querySelector('.cart__container');

      if (microcartContainer && checkoutBtnContainer && cartContainer) {
        const microcartHeight = microcartContainer.offsetHeight;
        const checkoutBtnHeight = checkoutBtnContainer.offsetHeight;
        const cartContainerHeight = cartContainer.offsetHeight;
        this.longContainer = microcartHeight < checkoutBtnHeight + cartContainerHeight;
      }
    },
    setLoadingState (loadingState, force) {
      if (loadingState || force) this.loadingTotal = loadingState;
      // false values are better handled by the totals watcher to ensure display is up to date
      // unless force is true to handle any errors where total won't change
    },
    addDiscountVoucher () {
      this.addVoucherPressed = true;
      setTimeout(() => {
        // wait for rendering
        document.getElementById('voucher-code')?.focus();
        this.inputAsPlaceholder = true;
      }, 100);
    },
    resetVoucherError () {
      this.voucherError = false;
    },
    inputAsPlaceholderCheck (inputValue) {
      this.inputAsPlaceholder = inputValue.length === 0;
    },
    clearVoucher () {
      this.loadingTotal = true;
      this.removeVoucher();
      this.addVoucherPressed = false;
    },
    async setVoucher (voucherInput) {
      if (!this.loadingTotal) {
        this.loadingTotal = true;
        this.voucherError = false;
        const result = await this.applyVoucher(voucherInput);
        if (result === true) {
          postTrackEvent('Voucher added', {
            voucherEntered: voucherInput,
            whereAdded: 'cart',
            discountAmount: this.voucherApplied?.discountValue,
            screenType: isMobile() ? 'mobile' : 'desktop',
          });
        } else {
          this.loadingTotal = false;
          this.voucherError = true;
          this.voucherErrorMsg = result;
          postTrackEvent('Voucher adding error', {
            error: result,
            voucherEntered: voucherInput,
            location: 'cart',
            cart: { lines: this.productsInCart, total: this.getTotal }
          });
        }
      }
    },
    closeMicrocartExtend () {
      if (!this.instantPaymentComplete && !this.instantPaymentSuccess) {
        postTrackEvent('Closed cart with empty basket X', { value: true });
        this.closeMicrocart();
        this.addVoucherPressed = false;
        if (this.belowMinimumThreshold) {
          postTrackEvent('Closed cart below threshold X', { value: true });
        } else if (!this.belowMinimumThreshold && !this.productsInCart.length) {
          postTrackEvent('Closed cart with empty basket X', { value: true });
        } else {
          postTrackEvent('Closed cart above threshold X', { value: true });
        }
      }
    },
    onEscapePress () {
      postSavedEvents();
      if (!this.instantPaymentComplete && !this.instantPaymentSuccess) {
        this.closeMicrocart();
      }
    },
    clearCart () {
      const uri = window.parent.location.hostname + '/message/';
      const data = {
        type: 'warning',
        message: this.PATCH_I18N.cart.messages.clear_cart,
        action1: { label: this.PATCH_I18N.general.cancel, action: 'close' },
        action2: {
          label: this.PATCH_I18N.general.ok,
          action: async () => {
            await this.$store.dispatch('cart/clear', { recreateAndSyncCart: false }); // just clear the items without sync
            await this.$store.dispatch('cart/sync', { forceClientState: true });
          }
        },
        hasNoTimeout: true
      };
      window.postMessage(data, uri);
    },
    forceRerenderCheckoutBtn() {
      this.checkoutBtnKey += 1;
    },
    getLatestCheckoutBtnUuid() {
      // Designed to allow zombie child components to get
      // the latest checkout button ID
      // Passing a function as a prop is an anti-pattern but
      // it may be the only way to get a discarded CheckoutBtn to access
      // information from the parent's scope
      return this.latestCheckoutBtnUuid;
    },
    recordLatestCheckoutBtnUuid(uuid) {
      this.latestCheckoutBtnUuid = uuid;
    },
    instantPayEligibileEvent(canMakePaymentResult) {
      if (!this.instantPayEligibileEventSent) {
        postTrackEvent('User eligible for instant pay', {
          cartTotal: this.getTotal,
          numberOfProducts: this.productsInCart.length,
          ...canMakePaymentResult
        });
        this.instantPayEligibileEventSent = true;
      }
    },
    doInstantPaymentComplete(result, paymentIntent) {
      this.instantPaymentComplete = true;
      this.instantPaymentSuccess = true;
      const cartToken = this.$store.getters['cart/getCartToken'];
      const intentDisplay = `${paymentIntent.intent_id} for ${paymentIntent.amount} ${paymentIntent.currency}`;

      // TODO: propagate paymentCancelled from CheckoutBtn
      if (this.paymentCancelled) {
        // user attempted to cancel the payment
        // TODO: Notify CS that user attempted to cancel but payment succeeded?
      }

      // call create-order API
      this.$store.dispatch('cart/createOrder', { paymentResponse: result }).then((result) => {
        const orderToken = result.order_token;
        if (orderToken) {
          // redirect to order confirmation page
          window.location.href = `${getPrefix()}order/${orderToken}/`;
        } else {
          // uh oh, we have a payment but no order...
          // TODO: Notify CS that payment was successful but order not created?
          const msg = `Payment taken for cart with token ${cartToken} but apparently no order created. Payment intent: ${intentDisplay}`;
          Sentry.captureMessage(msg);
          // hide the loading state
          this.instantPaymentSuccess = false;
        }
      }).catch((err) => {
        const msg = `Problem with order creation feedback for cart with token ${cartToken}. Taken payment intent: ${intentDisplay}`;
        Sentry.captureMessage(msg);
        // hide the loading state
        this.instantPaymentSuccess = false;
      });
    },
    calculateTotalToPay() {
      let total = this.getTotal + this.getDeliveryPriceValue;
      if (this.voucherApplied?.code && this.voucherApplied?.discountValue) {
        total = total - this.voucherApplied.discountValue;
      }
      return total;
    },
  }
};
</script>
